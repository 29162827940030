<template>
	<tr class="row d-flex">
		<td
			:class="fieldName === firstItem ? ' borderless' : ''"
			class="font-weight-bold col-lg-4 pt-3">
			<span
				class="font-weight-bold">
				{{ fieldName }}
				<div
					v-if="info"
					v-b-tooltip.hover.right
					:title="info"
					class="text-muted d-inline">
					<i class="fas fa-info-circle" />
				</div>
			</span>
			<span
				v-if="fieldInformation.editable && !showEdit && ['sm','xs','md'].includes(windowWidth)"
				class="btn btn-link float-right"
				@click="activeEditMode">{{ translate('edit') }}</span>
		</td>
		<td
			:class="(['sm','xs','md'].includes(windowWidth) || fieldName === firstItem) ? ' borderless' : ''"
			class="col-lg-4">
			<div v-if="!showEdit">
				<span
					v-if="couponKey.includes(keyValue)"
					class="text-muted border-0 form-control px-0 h-auto">
					{{ fieldInformation.value }}%
				</span>
				<span
					v-else-if="translateNoEdit.includes(keyValue)"
					:class="!textAreaComponent.includes(keyValue) ? 'form-control px-0 h-auto' : ''"
					class="text-muted border-0">
					{{ translate(fieldInformation.value) }}
				</span>
				<span
					v-else-if="smsKey.includes(keyValue) || displayName.includes(keyValue)"
					:class="!textAreaComponent.includes(keyValue) ? 'form-control px-0 h-auto' : ''"
					class="text-muted border-0 pt-1">
					{{ translate(`${!!fieldInformation.value}`) }}
				</span>
				<span
					v-else-if="fieldLinks.includes(keyValue)"
					class="text-muted border-0 form-control px-0 h-auto">
					<a
						:href="fieldInformation.value"
						target="_blank">{{ fieldInformation.value }}</a>
				</span>
				<span
					v-else-if="textAreaComponent.includes(keyValue) && allRoles.includes(fieldInformation.value)"
					:class="!textAreaComponent.includes(keyValue) ? 'form-control px-0 h-auto' : ''"
					class="text-muted border-0">
					{{ translate( fieldInformation.value) }}
				</span>
				<span
					v-else-if="emailRedirect.includes(keyValue)"
					class="text-muted border-0 form-control px-0 h-auto">
					<a
						:href="'mailto:' + fieldInformation.value"
						target="_blank">{{ fieldInformation.value }}</a>
				</span>
				<span
					v-else-if="booleanKeys.includes(keyValue)"
					class="text-muted border-0 form-control px-0 h-auto">
					{{ translate(`${!!fieldInformation.value}`) }}
				</span>
				<span
					v-else-if="numericComponent.includes(keyValue)"
					class="text-muted border-0 form-control px-0 h-auto">
					{{ fieldInformation.value }}
				</span>
				<span
					v-else
					:class="!textAreaComponent.includes(keyValue) ? 'form-control px-0 h-auto' : ''"
					class="text-muted border-0">
					{{ fieldInformation.value }}
				</span>
			</div>
			<div
				v-if="showEdit"
				class="form-group mb-0">
				<select
					v-if="selectComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:class="fieldErrors.length > 0 ? 'is-invalid' : ''"
					:disabled="!Object.keys(options).length > 0 ? true : false"
					type="text"
					name="status"
					class="form-control px-0 h-auto">
					<option
						v-if="!Object.keys(options).length > 0 ? true : false"
						value="">
						{{ translate('loading') }}
					</option>
					<option
						v-for="option in options"
						:key="option"
						:value="option">
						{{ translate(option) }}
					</option>
				</select>
				<textarea
					v-else-if="textAreaComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:class="fieldErrors.length > 0 ? 'is-invalid' : ''"
					rows="6"
					name="setting"
					class="form-control px-0 h-auto rounded-0 border-top-0 border-left-0 border-right-0 pt-1"
					@input="handleChange()" />
				<vue-tel-input
					v-else-if="telComponent.includes(keyValue)"
					:id="keyValue"
					ref="mobileNumberInput"
					v-model="newSettingVal"
					:placeholder="translate('enter_phone_number')"
					v-bind="bindProps"
					:class="fieldErrors.length > 0 ? 'is-invalid' : ''"
					name="setting"
					class="form-control px-0 h-auto rounded-0 border-top-0 border-left-0 border-right-0"
					@onInput="handleMobileChange" />
				<switch-toggle
					v-else-if="switchComponent.includes(keyValue)"
					id="switch-profile"
					v-model="newSettingVal"
					variant="success"
					pill
					:class="['sm','xs', 'md'].includes(windowWidth) ? '' : 'mt-2'"
					:remove-margin="true"
					:no-margin="true"
					@change="handleChange()" />
				<input
					v-else-if="numericComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:class="fieldErrors.length > 0 ? 'is-invalid' : ''"
					type="number"
					name="setting"
					class="form-control px-0 h-auto rounded-0 border-top-0 border-left-0 border-right-0 pt-1"
					@input="handleChange()">
				<input
					v-else
					:id="keyValue"
					v-model="newSettingVal"
					:class="fieldErrors.length > 0 ? 'is-invalid' : ''"
					type="text"
					name="setting"
					class="form-control px-0 h-auto rounded-0 border-top-0 border-left-0 border-right-0 pt-1"
					@input="handleChange()">
				<template v-if="fieldErrors.length > 0">
					<span
						v-for="error in fieldErrors"
						:key="error"
						class="invalid-feedback animated fadeIn text-wrap"
						v-text="error" />
				</template>
			</div>
		</td>
		<td
			:class="(['sm','xs','md'].includes(windowWidth) || fieldName === firstItem) ? ' borderless' : ''"
			class="col-lg-4">
			<div v-if="fieldInformation.editable">
				<template v-if="showEdit">
					<span
						class="btn btn-primary float-right border-round-right pointer"
						@click="handleSave()">{{ translate('save') }}</span>
					<span
						class="btn btn-secondary float-right border-round-left pointer"
						@click="handleCancel()">{{ translate('cancel') }}</span>
				</template>
				<span
					v-if="!showEdit && !['sm','xs','md'].includes(windowWidth)"
					class="btn btn-link float-right edit-btn pt-1 pointer"
					@click="activeEditMode">{{ translate('edit') }}</span>
			</div>
		</td>
		<td
			v-if="(!['sm','xs','md'].includes(windowWidth)) || (showEdit && ['sm','xs','md'].includes(windowWidth))"
			class="col borderless" />
		<phone-verification-modal
			v-if="telComponent.includes(keyValue)"
			v-show="isPhoneVerificationRequired"
			:phone-number="newSettingVal"
			:open.sync="openPhoneVerificationModal"
			recaptcha-action-name="checkout_verification"
			@verified="handlePhoneVerified"
			@changeNumber="focusMobileNumberInput"
			@phoneValidationError="focusMobileNumberInput" />
	</tr>
</template>
<script>
import Vue from 'vue';
import { VueTelInput } from 'vue-tel-input';
import Switch from '@/components/Switch';
import { Profile } from '@/translations';
import {
	SMS_KEY as smsKey, TRANSLATE_NO_EDIT as translateNoEdit,
	SWITCH_COMPONENT as switchComponent, TEL_COMPONENT as telComponent,
	NUMERIC_KEYS as numericComponent,
	TEXT_AREA_COMPONENT as textAreaComponent, SELECT_COMPONENT as selectComponent,
	FIELD_LINKS as fieldLinks, EMAIL_REDIRECT_KEY as emailRedirect,
	CUSTOMER_COUPON_KEY as couponKey,
	DISPLAY_NAME_LEADERBOARD_KEY as displayName,
	BOOLEAN_KEYS as booleanKeys,
} from '@/settings/Profile';
import { allRoles, admin } from '@/settings/Roles';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';
import WindowSizes from '@/mixins/WindowSizes';
import PhoneVerificationModal from '@/components/PhoneVerificationModal/index.vue';
import { PHONE_VERIFICATION_STATES, VERIFY_PHONE_COUNTRIES } from '@/settings/PhoneVerification';
import ProfileInfo from '@/util/Profile';
import PhoneVerification from '@/util/PhoneVerification';

const eventBus = new Vue();
export default {
	name: 'GeneralSettingField',
	messages: [Profile],
	components: {
		PhoneVerificationModal,
		VueTelInput,
		'switch-toggle': Switch,
	},
	mixins: [WindowSizes],
	props: {
		fieldName: {
			type: String,
			default: '',
		},
		fieldInformation: {
			type: Object,
			default() {
				return {};
			},
		},
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
		keyValue: {
			type: String,
			default: '',
		},
		info: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		maxChar: {
			type: Number,
			default: 0,
		},
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		firstItem: {
			type: String,
			default: '',
		},
		verificatePhoneNumber: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			profile: new ProfileInfo(),
			phoneVerification: new PhoneVerification(),
			editMode: false,
			newSettingVal: this.fieldInformation.value,
			active: false,
			smsKey,
			couponKey,
			displayName,
			booleanKeys,
			switchComponent,
			numericComponent,
			telComponent,
			textAreaComponent,
			selectComponent,
			translateNoEdit,
			fieldLinks,
			emailRedirect,
			allRoles,
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			availableCountries,
			admin,
			openPhoneVerificationModal: false,
			verificationPhoneToken: '',
			mobileNumberVerified: '',
			currentVerificationState: PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED,
			validationErrors: [],
		};
	},
	computed: {
		fieldErrors() {
			return [...this.errors, ...this.validationErrors];
		},
		showEdit() {
			if (this.editMode || this.fieldErrors.length > 0) {
				return true;
			}
			return false;
		},
		isPhoneVerificationRequired() {
			return this.verificatePhoneNumber && this.currentVerificationState === PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED
				&& VERIFY_PHONE_COUNTRIES.includes(this.country)
				&& !this.isTheSameMobileNumber();
		},
		isOrganizationVerified() {
			return this.phoneVerification.data.response.data.response.is_verified;
		},
	},
	watch: {
		newSettingVal(newVal) {
			if (this.maxChar > 0) {
				this.newSettingVal = newVal.slice(0, this.maxChar);
			}
		},
		editMode(newVal) {
			if (newVal === true) {
				this.setFocus(this.keyValue);
			}
		},
		loading(newVal) {
			if (!newVal && this.active) {
				this.setFocus(this.keyValue);
			}
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = this.availableCountries;
		eventBus.$on('editMode', this.handleEventBus);
	},
	beforeDestroy() {
		eventBus.$off('editMode');
	},
	methods: {
		isTheSameMobileNumber() {
			return String(this.mobileNumberVerified).replaceAll(' ', '').localeCompare(String(this.newSettingVal).replaceAll(' ', '')) === 0;
		},
		handlePhoneVerified(token) {
			this.verificationPhoneToken = token;
			this.mobileNumberVerified = this.newSettingVal ?? '';
			// Save the value when the phone is verified
			this.handleSave();
		},
		focusMobileNumberInput() {
			this.$nextTick(() => {
				this.$refs.mobileNumberInput.focus();
			});
		},
		handleCancel() {
			this.editMode = false;
			this.newSettingVal = this.fieldInformation.value;
			this.clearErrors();
		},
		handleChange() {
			this.editMode = true;
			this.clearErrors();
		},
		async handleSave() {
			// If there is no change, simply cancel the saving action by calling handleCancel
			if (this.newSettingVal === this.fieldInformation.value && this.fieldInformation.code !== 'binary_placement_preference') {
				this.handleCancel();
				return;
			}
			// If it is necessary to verify the phone number, show the modal instead of saving the new value.
			await this.phoneVerification.isOrganizationVerified(this.$user.details().id);
			if (this.isPhoneVerificationRequired && this.telComponent.includes(this.keyValue) && !this.isOrganizationVerified) {
				this.profile.updateProfile(this.$user.details().id, { mobile_number: this.newSettingVal, step: 'validate' }).then(() => {
					this.openPhoneVerificationModal = true;
				}).catch(() => {
					const { errors } = this.profile.data.errors;
					this.validationErrors = errors[this.keyValue] ?? [];
				});
				return;
			}
			this.editMode = false;
			this.$emit('editField', this.newSettingVal, this.verificationPhoneToken);
		},
		handleMobileChange() {
			this.editMode = true;
			this.clearErrors();
		},
		activeEditMode() {
			this.editMode = true;
			if (this.telComponent.includes(this.keyValue)) {
				this.mobileNumberVerified = this.newSettingVal;
			}
			eventBus.$emit('editMode', this.keyValue);
		},
		handleEventBus(key) {
			this.handleCancel();
			if (key === this.keyValue) {
				this.editMode = true;
				this.active = true;
			}
		},
		clearErrors() {
			this.$emit('clearField');
			this.validationErrors = [];
		},
	},
};
</script>

<style scoped>
	#switch-profile span.switch-slider {
		margin: 0 !important;
	}
	.table td {
		/*padding-top: unset;*/
		padding-bottom: unset;
	}
	.table td.borderless {
		border: 0px !important;
	}
	.table .row {
		margin-left: unset;
		margin-right: unset;
	}
	.table .row .btn.edit-btn {
		padding: unset;
	}
	div.is-invalid ~ .invalid-feedback {
		display: block;
	}
</style>
