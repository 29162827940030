<template>
	<div>
		<!-- Labeled Input -->
		<div
			v-if="groupTypes.input.includes(type) || groupTypes.textarea.includes(type) || showLabel"
			:class="showLabel ? 'm-0' : ''"
			class="form-group">
			<label
				v-if="label.length"
				:for="id"
				class="label">
				{{ label }}
				<span
					v-if="required"
					class="text-danger">*</span>
				<slot name="afterLabelInline" />
			</label>
			<!-- Input Text -->
			<input
				v-if="groupTypes.input.includes(type)"
				:id="id"
				v-model="inputValue"
				:maxlength="max"
				:placeholder="placeholder.length ? placeholder : label"
				:name="id"
				:type="type"
				:class="hasErrors ? 'is-invalid' : ''"
				:disabled="disabled"
				:autocomplete="autocomplete || id"
				:pattern="pattern"
				class="form-control">
			<!-- Input TextArea -->
			<textarea
				v-else-if="groupTypes.textarea.includes(type)"
				:id="id"
				v-model="inputValue"
				:name="id"
				:rows="areaRows"
				:class="hasErrors ? 'is-invalid' : ''"
				:disabled="disabled"
				:autocomplete="autocomplete || id"
				class="form-control" />
			<!-- Errors Alert -->
			<template v-if="hasErrors">
				<span
					v-for="error in errors"
					:key="error"
					class="invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</div>
		<!-- Prepend Input -->
		<div
			v-if="groupTypes.prepend.includes(type)"
			class="input-group mb-3">
			<!-- Prepend Input Text -->
			<b-input-group-prepend>
				<b-input-group-text>
					<span v-if="textPrepend">{{ icon }}</span>
					<i
						v-else
						:class="icon" />
				</b-input-group-text>
			</b-input-group-prepend>
			<input
				:id="id"
				v-model="inputValue"
				:placeholder="placeholder.length ? placeholder : label"
				:maxlength="max"
				:name="id"
				:type="type.split('_').pop()"
				:class="hasErrors ? 'is-invalid' : ''"
				:disabled="disabled"
				:autocomplete="autocomplete || id"
				:pattern="pattern"
				class="form-control">
			<!-- Errors Alert -->
			<template v-if="hasErrors">
				<span
					v-for="error in errors"
					:key="error"
					class="invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</div>
		<!-- Prepend Password -->
		<div
			v-if="groupTypes.prependPassword.includes(type)"
			class="input-group mb-3">
			<input-password
				:id="id"
				v-model="inputValue"
				:placeholder="placeholder.length ? placeholder : label"
				:maxlength="max"
				:name="id"
				group-class="m-0"
				:input-class="`${hasErrors ? 'is-invalid' : ''} form-control`"
				toggle-class="form-control"
				:disabled="disabled"
				:autocomplete="autocomplete || id">
				<template #prepend>
					<!-- Prepend Input Text -->
					<b-input-group-prepend>
						<b-input-group-text>
							<span v-if="textPrepend">{{ icon }}</span>
							<i
								v-else
								:class="icon" />
						</b-input-group-text>
					</b-input-group-prepend>
				</template>
			</input-password>
			<!-- Errors Alert -->
			<template v-if="hasErrors">
				<span
					v-for="error in errors"
					:key="error"
					class="d-block invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</div>
		<!-- Password -->
		<div
			v-if="groupTypes.password.includes(type)"
			:class="showLabel ? 'm-0' : ''"
			class="form-group">
			<label
				v-if="label.length"
				:for="id"
				class="label">
				{{ label }}
				<span
					v-if="required"
					class="text-danger">*</span>
				<slot name="afterLabelInline" />
			</label>
			<input-password
				:id="id"
				v-model="inputValue"
				:placeholder="placeholder.length ? placeholder : label"
				:maxlength="max"
				:name="id"
				group-class="m-0"
				:input-class="`${hasErrors ? 'is-invalid' : ''} form-control`"
				toggle-class="form-control"
				:disabled="disabled"
				:autocomplete="autocomplete || id" />
			<!-- Errors Alert -->
			<template v-if="hasErrors">
				<span
					v-for="error in errors"
					:key="error"
					class="d-block invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</div>
	</div>
</template>
<script>
import InputPassword from '@/components/InputPassword';
import { INPUT_TYPES as TYPES, INPUT_TEXT_MAX_LENGTH } from '@/settings/Inputs';

export default {
	name: 'InputText',
	components: { InputPassword },
	props: {
		id: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		setterValue: {
			type: [String, Number],
			default: '',
		},
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
		type: {
			type: String,
			default: TYPES.default,
			validator: (value) => {
				const isValid = Object.keys(TYPES).find((item) => TYPES[item] === value);
				return !!isValid.length;
			},
		},
		areaRows: {
			type: String,
			default: '5',
		},
		required: {
			type: Boolean,
			default: false,
		},
		textPrepend: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: '',
		},
		pattern: {
			type: String,
			default: null,
		},
		showLabel: {
			type: Boolean,
			default: false,
		},
		max: {
			type: Number,
			default: INPUT_TEXT_MAX_LENGTH,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		autocomplete: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputValue: '',
			groupTypes: {
				prepend: [TYPES.prependText],
				prependPassword: [TYPES.prependPassword],
				input: [TYPES.text, TYPES.number],
				password: [TYPES.password],
				textarea: [TYPES.textarea],
			},
		};
	},
	computed: {
		hasErrors() {
			if (this.errors.length > 0) {
				return true;
			}
			return false;
		},
	},
	watch: {
		inputValue(newValue) {
			this.$emit('dataChanged', newValue);
		},
		setterValue(newVal) {
			this.inputValue = newVal;
		},
	},
	created() {
		this.inputValue = this.setterValue;
	},
};
</script>
<style lang="scss" scoped>
.is-invalid {
	.input-group-text {
		border-color: #f86c6b;
	}
}
</style>
