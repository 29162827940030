const WIDTH = 300;
const HEIGHT = 300;
const ROUNDED = 200;

export const EDITABLE_FIELDS = [
	'email',
	'username',
	'enable_sms',
	'twitter',
	'facebook',
	'mobile',
	// 'skype',
	'tiktok',
	'biography',
	'binary_placement_preference',
	'name',
	'instagram',
	'linkedin',
	'mobile_number',
	'auto_apply_customer_coupons',
	'use_display_name_leaderboard',
];

export const CUSTOMER_EDITABLE_FIELDS = [
	'email',
	'username',
	'first_name',
	'last_name',
	'mobile_number',
];

export const LABEL_TO_TRANSLATE = {
	national_identification_number: 'nin',
};
export const PROFILE_DIMENSIONS = { WIDTH, HEIGHT, ROUNDED };
export const DATE_KEYS = ['created_at', 'birthdate', 'upgrade_date_limit', 'activation_date', 'deactivation_date', 'qualification_date', 'yearly_renewal_date', 'next_purchase_date', 'next_autoship_date'];
export const TRANSLATED_KEYS = ['status', 'position', 'country', 'rank', 'permanent_rank', 'product', 'gender', 'qualification_status'];
export const BOOLEAN_KEYS = ['is_migrated', 'is_onboard', 'enable_sms', 'qualified', 'is_company', 'tfa_enabled', 'enable_autoship', 'is_locked', 'use_display_name_leaderboard', 'app_form_received'];
export const NUMERIC_KEYS = ['auto_apply_customer_coupons'];
export const SMS_KEY = ['enable_sms'];
export const EMAIL_KEY = ['email'];
export const EMAIL_REDIRECT_KEY = ['sponsor_email'];
export const USERNAME_KEY = ['username'];
export const MOBILE_NUMBER_KEY = /mobile_number|mobile_[\d+]/; // Regex because the mobile name takes multiple values
export const CUSTOMER_COUPON_KEY = ['auto_apply_customer_coupons'];
export const DISPLAY_NAME_LEADERBOARD_KEY = ['use_display_name_leaderboard'];
export const BLACKLIST_FIELDS = [];
export const DEFAULT_PROFILE_PICTURE_SIZE = 'medium';
export const PROFILE_PICTURE_FIELD_NAME = 'profile_photo';
export const MAX_CHAR_BIOGRAPHY = 100;
export const MAX_CHAR_DISPLAY_NAME = 60;
export const SWITCH_COMPONENT = ['enable_sms', 'qualified', 'is_company', 'tfa_enabled', 'is_locked', 'use_display_name_leaderboard', 'app_form_received'];
export const TEL_COMPONENT = ['mobile_number'];
export const TEXT_AREA_COMPONENT = ['biography'];
export const SELECT_COMPONENT = ['binary_placement_preference', 'status', 'product', 'gender', 'rank', 'permanent_rank'];
export const DATE_COMPONENT = ['created_at', 'birthdate', 'upgrade_date_limit', 'activation_date', 'qualification_date', 'yearly_renewal_date', 'next_purchase_date', 'next_autoship_date'];
export const PASSWORD_COMPONENT = ['password', 'new_password', 'wallet_new_password'];
export const PAYEE_USERNAME_COMPONENT = ['payee_username'];
export const TRANSLATE_NO_EDIT = ['binary_placement_preference'];
export const FIELD_LINKS = ['replicated_url', 'sponsor_replicated_site'];
export const GENDERS = ['male', 'female'];
export const REQUIRED_NIN_COUNTRIES = ['US'];
export const BINARY_PLACEMENT_OPTIONS = [
	'left',
	'right',
	'weak',
	'power',
];
export const INVISIBLE_FIELDS = [
	'email_status',
	'middle_name',
	'national_identification_number_value',
	'tfa_enabled',
	'enable_autoship',
	'duplicated_info',
];
export const PROFILE_INVISIBLE_FIELDS = [
	'email_status',
	'middle_name',
	'is_migrated',
	'is_onboard',
	'is_company',
	'access_group',
	'national_identification_number_value',
	'type',
	'tfa_enabled',
	'duplicated_info',
];
export const EDITABLE_PERSONAL_FIELDS = [
	'email',
	'first_name',
	'last_name',
	'gender',
	'username',
	'mobile_number',
	'birthdate',
	'national_identification_number',
	'status',
	'enable_sms',
	'created_at',
	'company',
	'tax_id',
	'is_company',
	'is_locked',
	'app_form_received',
];

export const EDITABLE_ACCOUNT_FIELDS = [
	'product',
	'binary_placement_preference',
	'rank',
	'permanent_rank',
	'activation_date',
	'qualified',
	'qualification_date',
	'upgrade_date_limit',
	'yearly_renewal_date',
	'next_purchase_date',
	'next_autoship_date',
];

export const RESTRICTED_ACCOUNT_FIELDS = [
	'binary_placement_preference',
	'product',
	'permanent_rank',
	'rank',
	'qualified',
	'qualification_date',
];

export const EDITABLE_SYSTEM_CREDENTIALS_FIELDS = [
	'username',
	'password',
	'tfa_enabled',
];

export const CUSTOMER_PERSONAL_FIELDS = [
	'email_status',
	'email',
	'first_name',
	'last_name',
	'mobile_number',
	'status',
	'created_at',
	'enable_sms',
	'username',
	'duplicated_info',
];

export const CUSTOMER_ACCOUNT_FIELDS = [
	'next_autoship_date',
	'enable_autoship',
];

export const CUSTOMER_SYSTEM_CREDENTIALS_FIELDS = [
	// 'username',
	// 'password',
	// 'tfa_enabled',
];

export const SHOW_DUPLICATED_FIELDS = [
	'email',
	'mobile_number',
];

export default {};
